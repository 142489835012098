import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { ComponentPortal} from '@angular/cdk/portal';
import { Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TooltipComponent} from './tooltip.component';


@Directive({
  selector: '[appTooltip]',
  standalone: true
})
export class TooltipDirective {
  @Input('appTooltip') text: string;

  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef) {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -8,
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    this.overlayRef.outsidePointerEvents().pipe(
      takeUntilDestroyed()
    ).subscribe(() => {
      this.hide();
    });
  }

  @HostListener('mouseenter')
  show(): void {
    const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    tooltipRef.instance.text = this.text;
  }

  @HostListener('mouseleave')
  hide(): void {
    this.overlayRef.detach();
  }
}
